document.addEventListener('turbolinks:load', async () => {
  const buttonDisabled = () => {
    if (organizationForm.value ==='') {
      submitWithOrganizationButton.disabled = true
    } else {
      submitWithOrganizationButton.disabled = false
    }
  }

  const organizationForm = document.getElementById('showcase_organization_id')
  const submitWithOrganizationButton = document.getElementById('submit_with_organization')
  if (organizationForm && submitWithOrganizationButton) {
    buttonDisabled()
  
    organizationForm.addEventListener('change', (e) => {
      buttonDisabled()
    })
  }
})
